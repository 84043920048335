import React, { useEffect, useRef, useState } from 'react'
import { StyledProyectos } from '../themes/StyledProyectos'
import { useShowProyectos } from '../hooks/useProyectos'
import Editar from "../assets/svg/editar.svg"
import Eliminar from "../assets/svg/Eliminar.svg"
import Swal from 'sweetalert2'
import seguimiento from '../assets/img/tiempo-rapido.png'
import { useShowDataTable } from '../hooks/useShowDataTable'
import Opciones from '../assets/svg/opciones.svg'
import moment, { now } from 'moment/moment'
import Loader from '../Componentes/Loader'
import add from '../assets/svg/add.svg'
import flechaAbajo from "../assets/svg/abajo.svg"
import Buscar from '../assets/svg/buscar.svg'
import Registro from '../Componentes/Registro'
import Paginador from '../Componentes/Paginador'
import Modal from '../Componentes/Modal'
import { StyledAgregarAct } from '../themes/StyledAgregarAct'
import { useFecha } from '../hooks/useFecha'
import { useGuardarProyectos } from '../hooks/useGuardarProyectos'
import { useAuth } from '../Context/Context'
import useCancelarProyectos from '../hooks/useCancelarProyectos'
import useDetailsProyect from '../hooks/useDetailsProyect'
import { useAlert } from '../hooks/useAlert'
import InputPKT1 from '../Componentes/InputPKT1'
import SelectPKT1 from '../Componentes/SelectPKT1'
import Agregar from '../Componentes/Agregar'
import TextAreaPKT1 from '../Componentes/TextAreaPKT1'
import BotonesAcciones from '../Componentes/BotonesAcciones'
import TablaPKT1 from '../Componentes/TablaPKT1'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { useValidacion } from '../hooks/useValidacion'

const CatalagoProyectos = () => {



    const { mostrarProyectos, showDatosPoryectos, MostrarEstatusProyectos, showSelect, setShowSelect, loading, setLoading, TotalPaginas, TotalRegistros, Pagina, setPagina } = useShowProyectos();

    const { showDetailsProyect, DetallesProyecto, nombreProyecto, setNombreProyecto, descripcionProyecto, setDescripcionProyecto, fechaInicio, setFechaInicio, fechaFin, setFechaFin, status, setStatus } = useDetailsProyect();


    const { fechaActualMostrar, formatInsertFecha, formatprimerDiaMes, formatFecha, unMesDespues } = useFecha();

    const { CancelarProyectos, ProyectoCancelar, setPoryectoCancelar } = useCancelarProyectos();


    const [ids, setIds] = useState(0);

    const [buscar, setBuscar] = useState("");

    const [cambiarBoton, setCambiarBoton] = useState(false);

    //Valor del select
    const [valorSelect, setValorSelect] = useState(10);

    const [showRegistro, setShowRegistro] = useState(false);

    const [filtroEstatus, setFiltroEstatus] = useState("Todos");

    const [mostrarBotones, setMostrarBotones] = useState("");

    const [ desactivarBoton, setDesactivarBoton] = useState(false);



    // const [ValidarCampos, setValidarCampos] = useState({
    //     nombre: false,
    //     descripcion: false,
    //     fechaInicio: false,
    //     fechaFin: false
    // });

    const ContadorValidador = 0;


    const { guardarProyectos, guardarCambiosProyectos, showModalAgregar, setShowModalAgregar } = useGuardarProyectos();

    //Llamada al hook para mostrar el usurio en linea
    const { user } = useAuth();

    const { cambioSeccion } = useLocalStorage();



    const [Filtros, setFiltros] = useState({
        filtros: [
            0
        ]
    });

    const { alertError, alertSave } = useAlert();

    const FiltroEstatus = [
    {
        "id" : 0,
        "descripcion" : "Todos"
    },
    {
        "id" : 1,
        "descripcion" : "Creado"
    },
    {
        "id" : 2,
        "descripcion" : "Proceso"
    },
    {
        "id" : 3,
        "descripcion" : "Terminados",
    },
    {
        "id" : 4,
        "descripcion" : "Cancelado",
    },
    {
        "id" : 5,
        "descripcion" : "Pospuesto"
    }
    ];

    const FiltroRegistros = [
        {
            "id" : 10,
            "descripcion" : 10
        },
        {
            "id" : 25,
            "descripcion" : 25
        },
        {
            "id" : 100,
            "descripcion" : 100
        }
    ];

    const FormularioEstatus = [
        {
            "id" : 0,
            "descripcion" : "Creado"
        },
        {
            "id" : 1,
            "descripcion" : "Proceso"
        },
        {
            "id" : 2,
            "descripcion" : "Terminados",
        },
        {
            "id" : 3,
            "descripcion" : "Cancelado",
        },
        {
            "id" : 4,
            "descripcion" : "Pospuesto"
        }
        ];

    const [idFiltradoEstatus,setIdFiltradoEstatus] = useState(0);

    const [ NumeroRegistros, setNumeroRegistros ] = useState(10);


    const { validadorObjetos, VerificadorCampos, LimpiadorObjetoValidador } = useValidacion();
    

    useEffect(() => {

        showDatosPoryectos(`${process.env.REACT_APP_API_PROYECTOS}Proyectos/Paginador?Buscar=&Pagina=1&RegistrosPorPagina=10`, Filtros);

        cambioSeccion("Catálogo");

    }, []);

    //funcion para mostrar  y ocultar opciones con los 3 puntos de la tabla
    const mostrarOpciones = (id, estatusC) => {

        if (id === ids) {
            setIds("");

        } else {
            setIds(id);
        }

        if (estatusC === 3) {
            setPoryectoCancelar(true);
        } else {
            setPoryectoCancelar(false);
        }
    }

    const OcultarOpciones = () => {

        setIds("");
    }

    let result = []

    if (!buscar) {
        result = mostrarProyectos
    } else {
        result = mostrarProyectos.filter((proyecto) =>
            proyecto.nombre.toLowerCase().includes(buscar.toLowerCase())
        );
    }


    const ocultarListadoSelect = () => {
        setShowSelect(false);
    }

    const SiguientePagina = async () => {
        console.log(Filtros)
        if (TotalPaginas == 1) {
            setPagina(1);
        } else if (Pagina < TotalPaginas) {
            setPagina(Pagina + 1);
            await showDatosPoryectos(`${process.env.REACT_APP_API_PROYECTOS}Proyectos/Paginador?Pagina=${Pagina + 1}&RegistrosPorPagina=${TotalRegistros}`, Filtros);
        }
    }

    const PaginaAnterior = async () => {
        if (Pagina > 1) {
            setPagina(Pagina - 1);
            await showDatosPoryectos(`${process.env.REACT_APP_API_PROYECTOS}Proyectos/Paginador?Buscar=&Pagina=${Pagina - 1}&RegistrosPorPagina=${TotalRegistros}`, Filtros);
        }
    }

    const PrimeraPagina = async () => {
        if (Pagina != 1) {
            setPagina(1);
            await showDatosPoryectos(`${process.env.REACT_APP_API_PROYECTOS}Proyectos/Paginador?Buscar=&Pagina=1&RegistrosPorPagina=${TotalRegistros}`, Filtros);
        }
    }

    const UltimaPagina = async () => {
        if (TotalPaginas != 1) {
            setPagina(TotalPaginas);
            await showDatosPoryectos(`${process.env.REACT_APP_API_PROYECTOS}Proyectos/Paginador?Buscar=&Pagina=${TotalPaginas}&RegistrosPorPagina=${TotalRegistros}`, Filtros);
        }
    }

    const cambiarValorSelect = async (e) => {
        let numeroPaginas = e.target.value;
        setNumeroRegistros(e.target.value);
        await showDatosPoryectos(`${process.env.REACT_APP_API_PROYECTOS}Proyectos/Paginador?Pagina=1&RegistrosPorPagina=${numeroPaginas}`, Filtros);
    }


    const handleEstatus = async (e) => {

        let numberEstatus = parseInt(e.target.value);

        setIdFiltradoEstatus(e.target.value);
        
        let FiltradoEstatus = {
            "filtros": [
                numberEstatus
            ]
        }
        
        setFiltros({ ...Filtros, filtros: [numberEstatus] });

        await showDatosPoryectos(`${process.env.REACT_APP_API_PROYECTOS}Proyectos/Paginador?Pagina=1&RegistrosPorPagina=${TotalRegistros}`, FiltradoEstatus);
    }

    const mostrarModal = (e) => {
        limpiarModal();
        setMostrarBotones("Guardar");
        setShowModalAgregar(true);
    }

    const limpiarModal = async () => {
        setNombreProyecto("");
        setDescripcionProyecto("");
        setFechaInicio("");
        setFechaFin("");
        setStatus(0);

        // for (let propiedad in ValidarCampos) {
        //     setValidarCampos({ [propiedad]: false });
        // }
    }

    const cerrarModalAgregar = () => {
        setShowModalAgregar(false);
        limpiarModal();
        LimpiadorObjetoValidador();
    }



    const handelSubmit = async (e) => {

        e.preventDefault();
        
        setDesactivarBoton(true);

        setLoading(true);

        let DatosProyecto = {
            "nombre": nombreProyecto,
            "descripcion": descripcionProyecto,
            "fechaInicio": fechaInicio,
            "fechaFin": fechaFin,
            "estatus": 0
        }

        let ValidadorDatosProyecto = {
            "nombre": nombreProyecto,
            "descripcion": descripcionProyecto
        }

        // let ObjetoValidador = {}

        // for (let propiedad in ValidadorDatosProyecto) {
        //     if (ValidadorDatosProyecto[propiedad] === "") {
        //         ObjetoValidador = {
        //             [propiedad]: true
        //         }
        //         ValidarCampos[propiedad] = ObjetoValidador[propiedad]
        //         setValidarCampos({ ...ValidarCampos, [propiedad]: true })
        //     } else {
        //         ObjetoValidador = {
        //             [propiedad]: false
        //         }
        //         ValidarCampos[propiedad] = ObjetoValidador[propiedad]
        //         setValidarCampos({ ...ValidarCampos, [propiedad]: false })
        //     }
        // }


        //Llamada a la funcion del hook validacion
        validadorObjetos(ValidadorDatosProyecto);

        if (DatosProyecto.fechaInicio == "") {
            DatosProyecto.fechaInicio = moment().format('YYYY-MM-DD');
        }else if(DatosProyecto.fechaFin == ""){
            DatosProyecto.fechaFin = moment(unMesDespues).format('YYYY-MM-DD');
        }

        if (ValidadorDatosProyecto.nombre && ValidadorDatosProyecto.descripcion) {
            await guardarProyectos(`${process.env.REACT_APP_API_PROYECTOS}Proyectos/Agregar/${user.idusuario}`, DatosProyecto);
            await showDatosPoryectos(`${process.env.REACT_APP_API_PROYECTOS}Proyectos/Paginador?Buscar=&Pagina=1&RegistrosPorPagina=10`, Filtros);
            cerrarModalAgregar();
        } else {
            alertError("Favor de llenar todos los campos");
        }

        setDesactivarBoton(false);
        setLoading(false);
    }




    const CancelProject = async (idProject) => {

        mostrarOpciones(idProject);

        let sendCancelarPoryecto = { estatus: 3 };

        await CancelarProyectos(`${process.env.REACT_APP_API_PROYECTOS}Proyectos/Actualizar/${idProject}/${user.idusuario}`, sendCancelarPoryecto);

        await showDatosPoryectos(`${process.env.REACT_APP_API_PROYECTOS}Proyectos/Paginador?Buscar=&Pagina=1&RegistrosPorPagina=10`, Filtros);
    }


    const MostrarDatosProyecto = async (id, e) => {
        setLoading(true);
        mostrarOpciones(id);
        
        await showDetailsProyect(`${process.env.REACT_APP_API_PROYECTOS}Proyectos/${id}`);
        setShowModalAgregar(true);

        setMostrarBotones("Editar");

        console.log(DetallesProyecto);
        // setNombreProyecto(DetallesProyecto.nombre);
        // setFechaInicio(DetallesProyecto.fechaInicio);
        // setFechaFin(DetallesProyecto.fechaFin);

        // setStatus(mostrarProyectos.estatus);

        setLoading(false);

    }

    const saveChanges = async () => {

        setShowModalAgregar(false);

        let ffechaInicio = moment(DetallesProyecto?.fechainicio).format('YYYY-MM-DD')
        let ffechaFin = moment(DetallesProyecto?.fechafin).format('YYYY-MM-DD')

        //Hacemos una copia del arreglo original
        let copiaEditData = {
            ...DetallesProyecto,
            nombre: DetallesProyecto?.nombre,
            fechaInicio: ffechaInicio,
            fechaFin: ffechaFin,
            descripcion: DetallesProyecto?.descripcion,
            estatus: DetallesProyecto?.estatus
        }

        //Creamos un arreglo nuevo para mandarlo al servicio
        let datosModificados = {};

        //Actualizamos los propiedades del objeto original
        DetallesProyecto.nombre = nombreProyecto;
        DetallesProyecto.fechaInicio = fechaInicio;
        DetallesProyecto.fechaFin = fechaFin;
        DetallesProyecto.descripcion = descripcionProyecto;
        DetallesProyecto.estatus = status;

        //startDate = formatFecha(cambiarFecha);

        for (let propiedad in DetallesProyecto) {
            if (DetallesProyecto[propiedad] !== copiaEditData[propiedad]) {
                datosModificados[propiedad] = DetallesProyecto[propiedad];
                // console.log(datosModificados);
                // console.log(DetallesProyecto[propiedad]);
                // console.log(copiaEditData[propiedad]);

            }
        }

        await guardarCambiosProyectos(`${process.env.REACT_APP_API_PROYECTOS}Proyectos/Actualizar/${DetallesProyecto.id}/${user.idusuario}`, datosModificados);

        await showDatosPoryectos(`${process.env.REACT_APP_API_PROYECTOS}Proyectos/Paginador?Buscar=&Pagina=1&RegistrosPorPagina=10`, Filtros);

    }

    const cerrarModal = () => {
        setShowModalAgregar(false);
    }

    const BuscarProyecto = async(e) => {

        setLoading(true);
        
        setBuscar(e.target.value);

        let buscarAProyecto  = e.target.value;

        await showDatosPoryectos(`${process.env.REACT_APP_API_PROYECTOS}Proyectos/Paginador?Buscar=${buscarAProyecto}&Pagina=1&RegistrosPorPagina=10`, Filtros);


    }

    


    return (
        <>
            <StyledProyectos>
                {loading ?
                    <div className='PositionLoader'>
                        <Loader title="Proyectos" altoContenedor = {"0"} />
                    </div>
                    :
                    <div className='scroll'>
                        <div className='bodyRegistroActividades'>
                            <div className='acomodo'>
                                <div className='FiltrosTabla'>
                                    <div className='SeccionFiltro'>
                                        <div className='ContenderFiltroBuscar'>
                                            <InputPKT1 label={"Buscar"} placeholder={"Proyecto"} type={"search"} mostrar={true} background={"rgb(58, 147, 56)"} BtnImg={Buscar} Accion = {BuscarProyecto} value={buscar} />
                                        </div>
                                        <div className='ContenedorFiltroEstatus'>
                                            <SelectPKT1 label={"Estatus"} OpcionPorDefecto={FiltroEstatus[idFiltradoEstatus].descripcion} MapeoOpciones={FiltroEstatus} Filtrado={handleEstatus} MostrarOpcionPorDefecto={"none"}/>
                                        </div>
                                    </div>
                                    <div className='ContenedorAgregar'>
                                     <Agregar IconoAgregar={add} Accion={mostrarModal} />
                                    </div>
                                </div>
                            </div>
                            <div className='table'>
                                <div className='headerTable'>
                                    <p className='MID'>ID</p>
                                    <p className='MFechaRegistro'>Fecha de Registro</p>
                                    <p className='MProyecto'>Nombre del proyecto</p>
                                    <p className='MFechaInicio'>Fecha inicio</p>
                                    <p className='MFechaFin'>Fecha fin</p>
                                    <p className='MEstatus'>Estatus</p>
                                    <p className='MAccion'></p>
                                </div>
                                <div className='bodyTable'>
                                    {mostrarProyectos != null ?
                                        (
                                            mostrarProyectos?.map((item, index) => (
                                                <div className='col' key={item.id} onMouseLeave={() => OcultarOpciones()}>
                                                    <p className='DID'>{item.id}</p>
                                                    <p className='DFechaRegistro'>{moment(item.fecha).format('DD/MM/YYYY')}</p>
                                                    <p className='DProyecto'>{item.nombre}</p>
                                                    <p className='fecha DFechaInicio'>{moment(item.fechainicio).format('DD/MM/YYYY')}</p>
                                                    <p className='fecha DFechaFin'>{moment(item.fechafin).format('DD/MM/YYYY')}</p>
                                                    <p className='DEstatus'>{MostrarEstatusProyectos(item.estatus)}</p>

                                                    <div className='seleccionarOpciones' onClick={() => mostrarOpciones(item.id, item.estatus)} >
                                                        {/* {ids === item.id ? `seleccionarOpcionesRegistro seleccionarOpciones` : `seleccionarOpciones`} */}
                                                        <img id={item.id} className= "seleccionarOpciones" src={Opciones} alt="Opciones" />
                                                    </div>
                                                    <div className='backOpciones' onMouseLeave={() => OcultarOpciones()}>
                                                        <div className="ListadoOpciones opciones" id='ListadoSeguimiento' style={ids === item.id ? { display: "block" } : { display: 'none' }}>

                                                            <div className='opcionSeguimiento' onClick={() => MostrarDatosProyecto(item.id)}>
                                                                <div className='iconoEditar'>
                                                                    <img src={Editar} alt="IconoEditar" />
                                                                </div>
                                                                <p>Editar</p>
                                                            </div>
                                                            <div className={ProyectoCancelar ? "actividadesCanceladas" : "opcionSeguimiento"} onClick={() =>
                                                                Swal.fire({
                                                                    title: 'Estas seguro que deseas cancelar el proyecto?',
                                                                    text: `${item.id}.- ${item.nombre}`,
                                                                    icon: 'warning',
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: '#3085d6',
                                                                    cancelButtonColor: '#d33',
                                                                    confirmButtonText: 'Si'
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        CancelProject(item.id);
                                                                    }
                                                                })} >
                                                                <div className='iconoEliminar'>
                                                                    <img src={Eliminar} alt="IconoEliminar" />
                                                                </div>
                                                                <p disabled={ProyectoCancelar ? true : false}>Cancelar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))) : (<div className='nullData'> <p> No se encontraron datos </p></div>)}
                                </div>
                            </div>
                            <div className='cambioRegistros'>
                                <div className='Registro'>
                                    <SelectPKT1 label={"Registros"} OpcionPorDefecto={NumeroRegistros} MapeoOpciones={FiltroRegistros} Filtrado={cambiarValorSelect} MostrarOpcionPorDefecto="none" />
                                </div>
                                <div className='Paginador'>
                                    <Paginador paginaActual={Pagina} next={SiguientePagina} ultimaPagina={TotalPaginas} lastPage={UltimaPagina} firstPage={PrimeraPagina} prevPagina={PaginaAnterior} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </StyledProyectos>


            <Modal estado={showModalAgregar} cambiarEstado={() => cerrarModalAgregar()} titulo="Agregar Proyecto">
                <StyledAgregarAct>
                    <div className='AgregarActividades'>
                        <div className='cardForm'>
                            <form className='BodyForm'>
                                <InputPKT1 label={"Titulo"} value={nombreProyecto} type={"text"} mostrar={"none"} desactivar={false} CampoValidado={VerificadorCampos.nombre} placeholder={"Titulo del proyecto"} Accion={(e) => setNombreProyecto(e.target.value)} size={50}/>
                                <TextAreaPKT1 label={"Descripción"} placeholder={"Descripción del proyecto"} size={500} value={descripcionProyecto} onchange={(e) => setDescripcionProyecto(e.target.value)} CampoValidado={VerificadorCampos.descripcion} />
                                <div className='ContenedorFooterFormulario'>
                                    <div className='divFecha'>
                                        <InputPKT1 label={"Fecha inicio"} type={"date"} mostrar={"none"} value ={fechaInicio === "" ? moment().format('YYYY-MM-DD') : fechaInicio} Accion={(e) => setFechaInicio(e.target.value)} />
                                    </div>
                                    <div className='divFecha'>
                                        <InputPKT1 label={"Fecha fin"} type={"date"} mostrar={"none"} value ={fechaFin === "" ? moment(unMesDespues).format('YYYY-MM-DD') : fechaFin} Accion={(e) => setFechaFin(e.target.value)} />
                                    </div>
                                    {   mostrarBotones !== "Editar" ?
                                    <div className='ContenedorInputEstatus'>
                                        <InputPKT1 label={"Estatus"} value={MostrarEstatusProyectos(status)} type={"text"} mostrar={"none"} desactivar={true} />
                                    </div> 
                                    :
                                        <SelectPKT1 label={"Estatus"} OpcionPorDefecto={MostrarEstatusProyectos(status)} MapeoOpciones={FormularioEstatus} MostrarOpcionPorDefecto="none" Filtrado={(e) => setStatus(e.target.value)} value={status} />
                                    }
                                </div>
                            {status === 3 ?
                                <div className='butons'>
                                    <BotonesAcciones accion={"Cerrar"} colorFondo={"#D60000"} colorLetra={"#FFF"} clickBoton={cerrarModal} />
                                </div> : mostrarBotones !== "Editar" ?
                                <div className='butons'>
                                    <BotonesAcciones accion={"Guardar"} colorFondo={"#3BAF29"} colorLetra={"#FFF"} clickBoton={handelSubmit} desactivar = {desactivarBoton} />
                                </div>
                                    :
                                <div className='butons'>
                                    <BotonesAcciones accion={"GuardarCambios"} colorFondo={"#3BAF29"} colorLetra={"#FFF"} clickBoton={saveChanges} />
                                </div>
                            }
                            </form>
                        </div>
                    </div>
                </StyledAgregarAct>
            </Modal>
        </>
    )
}

export default CatalagoProyectos
